import { FormInstance } from "antd/es/form";
import _ from "lodash";
import React from "react";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKullaniciKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKullaniciKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  sbKRoleList: any[];
  sbKRoleList_dummy: any[];
  getKullaniciKartbyId: any[];
  getKullaniciKartbyId_dummy: any[];
  getKullaniciDetayKartbyId: any[];
  getKullaniciDetayKartbyId_dummy: any[];
  CreateUserWithPassword: string;
  CreateUserWithPassword_dummy: string;
  CreateUser: string;
  CreateUser_dummy: string;
  getKUserID: any[];
  getKUserID_dummy: any[];
  sDeleteUserToRole: number;
  sDeleteUserToRole_dummy: number;
  SaveKullaniciDetay: number;
  SaveKullaniciDetay_dummy: number;
  AddUserToRole: boolean;
  AddUserToRole_dummy: boolean;
  EnableUser: any;
  EnableUser_dummy: any;
  SaveUser: any;
  SaveUser_dummy: any;
  DisableUser: any;
  DisableUser_dummy: any;
  isComp365616Visible: "visible" | "hidden";
  isCompkullanicikart_606468AuthorizationEnabled: "enabled" | "disabled";
}

export class KullaniciKart_ScreenBase extends React.PureComponent<IKullaniciKart_ScreenProps, any> {
  kullanicikart_203590_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "1fd5f4c0-9d89-4d30-8e08-b046b64c0e32",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 652011, PropertyName: "value", Value: "Kullanıcı Kartı" },
        { Id: 989416, PropertyName: "value", Value: "Ad" },
        { Id: 552048, PropertyName: "placeholder", Value: "..." },
        { Id: 45699, PropertyName: "value", Value: "Soyad" },
        { Id: 724677, PropertyName: "placeholder", Value: "..." },
        { Id: 3829, PropertyName: "value", Value: "Kullanıcı Adı" },
        { Id: 161468, PropertyName: "placeholder", Value: "..." },
        { Id: 380999, PropertyName: "value", Value: "Rol" },
        { Id: 203590, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 943504, PropertyName: "value", Value: "Şifre" },
        { Id: 392787, PropertyName: "placeholder", Value: "..." },
        { Id: 530121, PropertyName: "value", Value: "Kısa Ad (Nick)" },
        { Id: 628169, PropertyName: "placeholder", Value: "..." },
        { Id: 352455, PropertyName: "value", Value: "GSM" },
        { Id: 849463, PropertyName: "placeholder", Value: "..." },
        { Id: 697063, PropertyName: "value", Value: "Aktif" },
        { Id: 98555, PropertyName: "label", Value: "Vazgeç" },
        { Id: 606468, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.kullanicikart_552048_value_kuikaTextInputRef = React.createRef();
    this.kullanicikart_724677_value_kuikaTextInputRef = React.createRef();
    this.kullanicikart_161468_value_kuikaTextInputRef = React.createRef();
    this.kullanicikart_203590_value_kuikaSelectBoxRef = React.createRef();
    this.kullanicikart_392787_value_kuikaTextInputRef = React.createRef();
    this.kullanicikart_628169_value_kuikaTextInputRef = React.createRef();
    this.kullanicikart_849463_value_kuikaTextInputRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      sbKRoleList: [],
      getKullaniciKartbyId: [],
      getKullaniciDetayKartbyId: [],
      CreateUserWithPassword: "",
      CreateUser: "",
      getKUserID: [],
      sDeleteUserToRole: "",
      SaveKullaniciDetay: 0,
      AddUserToRole: false,
      EnableUser: "",
      SaveUser: "",
      DisableUser: "",
      isComp365616Visible: "hidden",
      isCompkullanicikart_606468AuthorizationEnabled: "enabled"
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kullanicikart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.KullaniciKartPageInit();
    }
    ReactSystemFunctions.disableIsNotAuthorized(this, "isCompkullanicikart_606468AuthorizationEnabled", [
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "Admin"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("kullanicikart", "");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["Admin"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);

      const language = localStorage.getItem("ml");
      let permissonErrorMessageText = "You do not have permission to access this page.";
      if (language === "tr_TR") {
        permissonErrorMessageText = "Bu sayfaya erişim yetkiniz yok.";
      } else if (language === "fr_FR") {
        permissonErrorMessageText = "Vous n'avez pas la permission d'accéder à cette page.";
      } else if (language === "de_DE") {
        permissonErrorMessageText = "Sie haben keine Berechtigung, auf diese Seite zuzugreifen.";
      } else if (language === "es_ES") {
        permissonErrorMessageText = "No tienes permiso para acceder a esta página.";
      }

      KMainFunctions.displayErrorNotification(permissonErrorMessageText);
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kullanicikart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.KullaniciKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      kullanicikart_552048_value: this.state.getKullaniciKartbyId?.at?.(0)?.firstName ?? undefined,
      kullanicikart_724677_value: this.state.getKullaniciKartbyId?.at?.(0)?.lastName ?? undefined,
      kullanicikart_161468_value: this.state.getKullaniciKartbyId?.at?.(0)?.userName ?? undefined,
      kullanicikart_203590_value: this.state.getKullaniciKartbyId?.at?.(0)?.rolID ?? undefined,
      kullanicikart_628169_value: this.state.getKullaniciDetayKartbyId?.at?.(0)?.nick ?? undefined,
      kullanicikart_849463_value: this.state.getKullaniciDetayKartbyId?.at?.(0)?.gsm ?? undefined,
      kullanicikart_31327_value: this.state.getKullaniciDetayKartbyId?.at?.(0)?.uygulamadaAktif ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  KullaniciKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      KUserID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KullaniciKart/KullaniciKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbKRoleList = result?.data.sbKRoleList;

    formVars.kullanicikart_203590_value =
      stateVars.getKullaniciKartbyId?.length > 0
        ? stateVars.getKullaniciKartbyId[0].rolID
        : this.state.getKullaniciKartbyId?.length > 0
        ? this.state.getKullaniciKartbyId[0].rolID
        : null;
    formVars.kullanicikart_203590_options = stateVars.sbKRoleList;
    stateVars.getKullaniciKartbyId = result?.data.getKullaniciKartbyId;
    formVars.kullanicikart_552048_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciKartbyId?.length > 0
        ? stateVars.getKullaniciKartbyId[0].firstName
        : this.state.getKullaniciKartbyId?.length > 0
        ? this.state.getKullaniciKartbyId[0].firstName
        : null
    );
    formVars.kullanicikart_724677_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciKartbyId?.length > 0
        ? stateVars.getKullaniciKartbyId[0].lastName
        : this.state.getKullaniciKartbyId?.length > 0
        ? this.state.getKullaniciKartbyId[0].lastName
        : null
    );
    formVars.kullanicikart_161468_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciKartbyId?.length > 0
        ? stateVars.getKullaniciKartbyId[0].userName
        : this.state.getKullaniciKartbyId?.length > 0
        ? this.state.getKullaniciKartbyId[0].userName
        : null
    );
    formVars.kullanicikart_203590_value =
      stateVars.getKullaniciKartbyId?.length > 0
        ? stateVars.getKullaniciKartbyId[0].rolID
        : this.state.getKullaniciKartbyId?.length > 0
        ? this.state.getKullaniciKartbyId[0].rolID
        : null;
    formVars.kullanicikart_203590_options = stateVars.sbKRoleList;
    stateVars.getKullaniciDetayKartbyId = result?.data.getKullaniciDetayKartbyId;
    formVars.kullanicikart_628169_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciDetayKartbyId?.length > 0
        ? stateVars.getKullaniciDetayKartbyId[0].nick
        : this.state.getKullaniciDetayKartbyId?.length > 0
        ? this.state.getKullaniciDetayKartbyId[0].nick
        : null
    );
    formVars.kullanicikart_849463_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciDetayKartbyId?.length > 0
        ? stateVars.getKullaniciDetayKartbyId[0].gsm
        : this.state.getKullaniciDetayKartbyId?.length > 0
        ? this.state.getKullaniciDetayKartbyId[0].gsm
        : null
    );
    formVars.kullanicikart_31327_value = ReactSystemFunctions.value(
      this,
      stateVars.getKullaniciDetayKartbyId?.length > 0
        ? stateVars.getKullaniciDetayKartbyId[0].uygulamadaAktif
        : this.state.getKullaniciDetayKartbyId?.length > 0
        ? this.state.getKullaniciDetayKartbyId[0].uygulamadaAktif
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KullaniciKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.kullanicikart_552048_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciKartbyId?.length > 0
        ? stateVars.getKullaniciKartbyId[0].firstName
        : this.state.getKullaniciKartbyId?.length > 0
        ? this.state.getKullaniciKartbyId[0].firstName
        : null
    );

    formVars.kullanicikart_724677_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciKartbyId?.length > 0
        ? stateVars.getKullaniciKartbyId[0].lastName
        : this.state.getKullaniciKartbyId?.length > 0
        ? this.state.getKullaniciKartbyId[0].lastName
        : null
    );

    formVars.kullanicikart_161468_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciKartbyId?.length > 0
        ? stateVars.getKullaniciKartbyId[0].userName
        : this.state.getKullaniciKartbyId?.length > 0
        ? this.state.getKullaniciKartbyId[0].userName
        : null
    );

    formVars.kullanicikart_203590_value = ReactSystemFunctions.value(
      this,
      stateVars.getKullaniciKartbyId?.length > 0
        ? stateVars.getKullaniciKartbyId[0].rolID
        : this.state.getKullaniciKartbyId?.length > 0
        ? this.state.getKullaniciKartbyId[0].rolID
        : null
    );

    stateVars.dataSource_203590 = this.state.sbKRoleList;
    stateVars.dataSource_203590 = this.state.sbKRoleList;
    formVars.kullanicikart_628169_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciDetayKartbyId?.length > 0
        ? stateVars.getKullaniciDetayKartbyId[0].nick
        : this.state.getKullaniciDetayKartbyId?.length > 0
        ? this.state.getKullaniciDetayKartbyId[0].nick
        : null
    );

    formVars.kullanicikart_849463_value = ReactSystemFunctions.toString(
      this,
      stateVars.getKullaniciDetayKartbyId?.length > 0
        ? stateVars.getKullaniciDetayKartbyId[0].gsm
        : this.state.getKullaniciDetayKartbyId?.length > 0
        ? this.state.getKullaniciDetayKartbyId[0].gsm
        : null
    );

    formVars.kullanicikart_31327_value = ReactSystemFunctions.value(
      this,
      stateVars.getKullaniciDetayKartbyId?.length > 0
        ? stateVars.getKullaniciDetayKartbyId[0].uygulamadaAktif
        : this.state.getKullaniciDetayKartbyId?.length > 0
        ? this.state.getKullaniciDetayKartbyId[0].uygulamadaAktif
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  KullaniciKartComponent_650051_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciKartComponent_98555_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KullaniciKartComponent_606468_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.prmNewEdit ?? this.props.screenInputs.prmnewedit, "X")) {
      KuikaAppManager.showSpinner(this);

      stateVars.CreateUserWithPassword = await ReactSystemFunctions.createUserWithPassword(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_161468_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_392787_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_552048_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_724677_value", "value", "", "", "")
        ),
        null,
        null,
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KullaniciKartComponent_606468_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KullaniciKartComponent_606468_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  KullaniciKartComponent_606468_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.prmNewEdit ?? this.props.screenInputs.prmnewedit, "N")) {
      stateVars.CreateUser = await ReactSystemFunctions.createUser(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_161468_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_552048_value", "value", "", "", "")
        ),
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_724677_value", "value", "", "", "")
        ),
        null,
        null,
        null
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KullaniciKartComponent_606468_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KullaniciKartComponent_606468_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  KullaniciKartComponent_606468_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_161468_value", "value", "", "", "")
        ),
        "string"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_161468_value", "value", "", "", "")
        ),
        "string"
      ),
      GSM_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_849463_value", "value", "", "", "")
        ),
        "string"
      ),
      Nick_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_628169_value", "value", "", "", "")
        ),
        "string"
      ),
      UygulamadaAktif_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_31327_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KUserID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getKUserID?.length > 0
            ? stateVars.getKUserID[0].id
            : this.state.getKUserID?.length > 0
            ? this.state.getKUserID[0].id
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciKart/KullaniciKartComponent_606468_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getKUserID = result?.data.getKUserID;
    stateVars.sDeleteUserToRole = result?.data.sDeleteUserToRole;
    stateVars.SaveKullaniciDetay = result?.data.saveKullaniciDetay;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KullaniciKartComponent_606468_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciKartComponent_606468_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "kullanicikart_203590_value", "value", "sbKRoleList ", "id", "name")
      ),
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "kullanicikart_161468_value", "value", "", "", "")
      )
    );
    stateVars.EnableUser = await ReactSystemFunctions.enableUser(
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "kullanicikart_161468_value", "value", "", "", "")
      )
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KullaniciKartComponent_606468_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  KullaniciKartComponent_606468_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_161468_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_552048_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_724677_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_203590_value", "value", "sbKRoleList ", "id", "name")
        ),
        "string"
      ),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("93179506-acfb-482c-8605-a2dd676b09a0", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_849463_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName("False", "boolean")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KullaniciKart/KullaniciKartComponent_606468_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveUser = result?.data.saveUser;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KullaniciKartComponent_606468_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KullaniciKartComponent_606468_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_31327_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.DisableUser = await ReactSystemFunctions.disableUser(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kullanicikart_161468_value", "value", "", "", "")
        )
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KullaniciKartComponent_606468_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KullaniciKartComponent_606468_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  KullaniciKartComponent_606468_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
